import { H1, H2 } from '@/common/ui'
import { SecurityCard } from '@/common/ui/SecurityCard'
import { cn } from '@/common/utils/utils'
import {
    useFetchCompanyBySecurityCode,
    useFetchEquityPrices,
    useFetchHistoricalPrice,
    useFetchTopTradedSecurities,
} from '@/trading/tradingQueries'
import { Link } from 'react-router-dom'

const data = {
    title: 'What shares should I buy first?',
    author: {
        name: 'Adam Verwey',
        role: 'CEO and founder of SIX',
        date: 'October 15, 2024',
        image: 'https://s3-alpha-sig.figma.com/img/5e88/4d36/1bb268b6bf4ec0def49cb4bc54560af0?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=CctGm--8KtjSd8c~hYxx7aIp12MVPKfqxtI9D2uAyGsNH1HMg9M4stL8rM0Vhc8HStwbDd1gmHwgKwB1sT8qcIev-F~1sbiBj7frsYMRa4gkMYSYFgYoEqYfgXZx1I1LUXRqwiYCegsnhAcnzrnqMQbTnXk-TlU5bKh8Uy3byz-hmePBnU1Gbvf3YL6eABLJln7vgS8s~JmgkEX4N1qrUTZTKdsMMUfRMnOQqEW1LHcToGbpPmgATVIm7H8UJ2~QrkHynrtTI~jKcGQDJCs4NlSXtm0GItlglpsXNClE6~ax8ilZdK0DLJllQPD-EawDU4GY2OPf9Q1HLuRTPfTsvw__',
    },
    sections: [
        {
            title: 'Summary',
            content:
                'I’ve just opened up a trading account on SIX. But what shares should I buy? Follow these tips from Adam to get started no matter what amount of money you’re starting off with.',
        },
        {
            title: 'Starting with between $500 to $2,000',
            content:
                'If you’re starting your share investing journey with around $500 up to $2,000, then a good place to start looking is at Exchange Traded Funds, or ETFs.\nAs the minimum amount you can initially invest in a company on the ASX is $500, if you were to choose to invest in one or two companies, then you’re putting all your eggs in those one or two baskets.\nWith an ETF, however, you can buy into a whole portfolio of companies. Investing in an ETF is a convenient way to invest in many different companies in just one trade. It’s a less risky way to invest than picking individual companies as your money is spread across many companies.\nThere’s a wide range of ETFs available on the ASX, and they usually have a particular theme. It might be a broad theme like “Australian shares” that invests in all the largest companies on the ASX, or a more specific theme like AI or renewable energy or food.\nAnother good thing about ETFs is that they are transparent. You can access information about how they select the companies for the portfolio, and provide a daily list of all the companies that the ETF is invested in. This is a great way to check that an ETF matches your values.\nWe’ve written a helpful blog about the Sustainability ETFs you can buy on SIX.\nThe following are the largest Sustainability ETFs on the ASX:',
            securities: [
                {
                    securityCode: 'ETHI',
                    name: 'Betashares Global Sustainability Leaders',
                },
                {
                    securityCode: 'FAIR',
                    name: 'Betashares Australian Sustainability Leaders',
                },
                {
                    securityCode: 'VESG',
                    name: 'Vanguard Ethically Conscious International Shares',
                },
                {
                    securityCode: 'IWLD',
                    name: 'iShares Core MSCI World ex Australia ESG ETF',
                },
                {
                    securityCode: 'VETH',
                    name: 'Vanguard Ethically Conscious Australian Shares',
                },
                {
                    securityCode: 'RARI',
                    name: 'Russell Australian Responsible Investment',
                },
                {
                    securityCode: 'IESG',
                    name: 'iShares Core MSCI Australia ESG Leaders ETF',
                },
                {
                    securityCode: 'ESGI',
                    name: 'Van Eck Vectors MSCI International Sustainable Equity',
                },
                {
                    securityCode: 'GRNV',
                    name: 'Van Eck MSCI Australian Sustainable Equity',
                },
            ],
        },
        {
            title: 'Starting with between $2,000 to $10,000',
            content:
                'ETFs aren’t just good for your first investment, they can also be a good diversified base for a larger share portfolio.\nWith a larger amount of money to invest, you have the opportunity to diversify and hold different types of ETFs. If you want to use ETFs to invest in both Australian and overseas companies, then you can buy an ETF with Australian shares and one with International shares in it. Or, if there’s a theme that you’re really into, like electric vehicles or video games or food - there’s a good chance there’s an ETF that invests in companies in that theme.\nWith a bit more money, you might also want to buy shares directly into a company. You could buy shares to support one of SIX’s activist campaigns, or else pick a company that you like.\nCompanies on the ASX provide a lot of reporting about their activities and financials, and if you’ve got the time then you should have a read before investing. However, here’s some helpful questions to ask yourself before making an investment in a company:\nHave I heard about this company before?\nDo I understand, and like, what this company does as its main business?\nDo I think this company offers a product or service that people will still want to use in the future?\nIf you like what a company does, and think what it does is important, then this can make the emotional side of investing a bit easier.',
            securities: [
                {
                    securityCode: 'ERTH',
                    name: 'Betashares Climate Change Solutions ETF',
                },
                {
                    securityCode: 'CLNE',
                    name: 'VanEck Global Clean Energy ETF',
                },
                {
                    securityCode: 'ACDC',
                    name: 'Global X Battery Tech & Lithium ETF',
                },
                {
                    securityCode: 'IEAT',
                    name: 'Betashares Future of Food ETF',
                },
                {
                    securityCode: 'DRIV',
                    name: 'BetaShares Electric Vehicles and Future Mobility ETF',
                },
            ],
        },
        {
            title: 'Starting with $10,000 or more',
            content:
                'With a bit more money, you can invest in more things. But the basics of investing stay the same no matter how much money you have to invest. So getting started with more than $10k to invest looks pretty similar to getting started with less than $10k to invest.\nETFs can be a great base for a portfolio as they can give diversification across many companies, many countries and many different sectors. With some additional funds, you may like to add shares in individual companies that you like, or support 1 or more of our shareholder activism campaigns.\nIf you want to see what companies other SIX users are buying, then these are the top 6 most traded companies on SIX:',
        },
    ],
}

function Security({ security }: { security: any }) {
    const { data: equityPrice } = useFetchEquityPrices(security.securityCode)
    const { data: equityDetails } = useFetchCompanyBySecurityCode(security.securityCode)
    const { data: historicalPrices } = useFetchHistoricalPrice(security.securityCode, 'YTD')
    const price = equityPrice?.buyPrice ?? equityPrice?.closingPrice
    if (!price || !historicalPrices || historicalPrices.length === 0) return

    const changeInPriceYTD = (price - historicalPrices[0]?.close) / historicalPrices[0]?.close
    const roundedChangeInPriceYTD = Math.round(changeInPriceYTD * 100) / 100

    return (
        <div className="self-stretch justify-start items-start gap-6 inline-flex">
            <Link className="w-full" to={`/equity/${security.securityCode}`}>
                <SecurityCard
                    securityCode={security.securityCode}
                    securityName={security.name ?? equityDetails?.companyName}
                    price={price}
                    withEthicalTags={false}
                    changeInPriceYTD={roundedChangeInPriceYTD}
                    historicalPrices={historicalPrices}
                />
            </Link>
        </div>
    )
}

export const GettingStarted = () => {
    const { data: topTradedSecurities } = useFetchTopTradedSecurities()

    return (
        <div className="px-8 pt-6 pb-20 flex-col justify-start items-start gap-12 inline-flex">
            <div className="self-stretch h-[132px] flex-col justify-start items-start gap-6 flex">
                <H1>{data.title}</H1>
                <div className="justify-start items-start gap-4 inline-flex">
                    <img
                        className="object-cover w-16 h-16 rounded-full shrink-0 overflow-hidden"
                        src={data.author.image}
                        alt={data.author.name}
                    />
                    <div className="self-stretch flex-col justify-center items-start gap-2 inline-flex">
                        <div className="text-black text-base font-medium leading-tight">
                            {data.author.name}, {data.author.role}
                        </div>
                        <div className="text-stone-700 text-base font-medium leading-tight">{data.author.date}</div>
                    </div>
                </div>
            </div>
            <div className="w-full flex-col justify-start items-start gap-6 inline-flex">
                <div className="text-black text-2xl font-normal leading-[28.80px]">On this page</div>
                <div className="self-stretch bg-[#f8f8f8] rounded-[10px] flex-col justify-start items-start flex">
                    {data.sections.map((section, index) => (
                        <button
                            key={index}
                            className="self-stretch text-left hover:bg-[#ecefe9] rounded-[10px] hover:font-semibold pl-9 pr-5 py-5 justify-start items-start gap-2.5 inline-flex cursor-pointer"
                            onClick={() => {
                                const element = document.getElementById(`section-${index}`)
                                if (element) {
                                    element.scrollIntoView({ behavior: 'smooth' })
                                }
                            }}
                        >
                            <div className="grow shrink basis-0 text-black leading-snug">{section.title}</div>
                        </button>
                    ))}
                </div>
            </div>
            <div className="self-stretch justify-start items-start gap-12 inline-flex">
                <div className="grow shrink basis-0 flex-col justify-start items-start gap-12 inline-flex">
                    {data.sections.map((section, index) => (
                        <div
                            id={`section-${index}`}
                            key={index}
                            className="self-stretch flex-col justify-start items-start gap-6 flex"
                        >
                            <div className="self-stretch justify-start items-center inline-flex">
                                <H2>{section.title}</H2>
                            </div>
                            <div className="self-stretch pr-6 rounded-[10px] justify-center items-center gap-10 inline-flex">
                                <div
                                    className={cn('self-stretch text-black leading-7 mb-4', {
                                        'border-l-8 border-[#d5d8d3] font-light pl-6 pt-2': index === 0,
                                    })}
                                >
                                    {section.content.split('\n').map((line, i) => (
                                        <p className="mb-3" key={i}>
                                            {line}
                                        </p>
                                    ))}
                                </div>
                            </div>
                            {section.securities && (
                                <div className="self-stretch flex-col justify-start items-start gap-6 flex">
                                    {section.securities.map((security, index) => (
                                        <Security key={index} security={security} />
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                    {topTradedSecurities && (
                        <div className="self-stretch flex-col justify-start items-start gap-6 flex">
                            {topTradedSecurities.map((security: any, index: number) => (
                                <Security key={index} security={security} />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
