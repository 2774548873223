import { InstrumentWithCampaignMetadataT } from '@/common/types'
import { Button } from '../common/ui/button'
import { cn } from '@/common/utils/utils'
import placeholderImg from '@/common/assets/intrument-placeholder.png'
import useCampaignStore from './campaignStore'
import { InfoIconTooltip } from '@/common/ui/tooltip'
import { Deadline } from '@/common/ui/Deadline'

type Props = {
    instruments: InstrumentWithCampaignMetadataT[]
    pledges?: { [key: string]: number } | null
    selectedInstrument: InstrumentWithCampaignMetadataT | null
    setSelectedInstrument: (instrument: InstrumentWithCampaignMetadataT) => void
}

const Instrument = ({
    instrument,
    isSelected,
    shareholdersPledged,
    shareholdersGoal,
    percentOfTarget,
    onInstrumentSelect,
}: {
    instrument: InstrumentWithCampaignMetadataT
    isSelected: boolean
    shareholdersPledged: number
    shareholdersGoal: number
    percentOfTarget: string
    onInstrumentSelect: () => void
}) => {
    return (
        <Button
            key={instrument.securityCode}
            onClick={onInstrumentSelect}
            className={cn('h-auto w-full p-0 text-left bg-transparent border-1 overflow-hidden')}
            midClassName="w-full"
            innerClassName="relative flex justify-between items-center w-full"
            variant="outline"
            fullWidth
        >
            <style>{`.${instrument.securityCode} { --bar-width: ${percentOfTarget}%; }`}</style>
            <div className={`absolute bg-white w-full h-full ${instrument.securityCode}`}>
                <div className={`bg-[#E4FBC6] w-[--bar-width] h-full`}></div>
            </div>
            <div className="z-10 flex px-4 py-1 items-center gap-2 sm:gap-4 w-full grow-1">
                <div className="w-5 h-5 rounded-full border border-gray bg-white grid place-items-center">
                    <div className={cn('rounded-full w-[10px] h-[10px]', `${isSelected ? 'bg-black' : 'bg-white'}`)} />
                </div>
                <div className={cn('flex py-2 space-x-3 text-inherit w-full')} title={instrument.companyName}>
                    <div className="bg-gray-100 self-center sm:self-auto rounded-full p-1 w-8 h-8 sm:w-10 sm:h-10 border-1 border-gray-300 shrink-0 overflow-hidden bg-white">
                        <img
                            src={`/images/companies/${instrument.securityCode.toLowerCase()}.webp`}
                            alt={instrument.securityCode}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null
                                currentTarget.src = placeholderImg
                            }}
                        />
                    </div>
                    <div className="flex flex-row justify-between w-full">
                        <div className="flex flex-col gap-1">
                            <span>
                                <span className="font-semibold">{instrument.securityCode}</span>
                                <span className="text-xs"> {instrument.companyName}</span>
                            </span>
                            <span className={cn('text-xs text-zinc-600 line-clamp-1')}>
                                <span className="font-semibold">
                                    {shareholdersPledged}/{shareholdersGoal}
                                </span>{' '}
                                shareholders
                            </span>
                            <div className="sm:hidden">
                                <Deadline deadline={instrument.campaignDeadline} iconSize={12} />
                            </div>
                        </div>
                        <div className="hidden sm:block self-center p-2">
                            <Deadline deadline={instrument.campaignDeadline} iconSize={16} />
                        </div>
                    </div>
                </div>
            </div>
        </Button>
    )
}

const InstrumentSixPick = ({
    instrument,
    isSelected,
    shareholdersPledged,
    shareholdersGoal,
    percentOfTarget,
    onInstrumentSelect,
    sixPickExplanation,
}: {
    instrument: InstrumentWithCampaignMetadataT
    isSelected: boolean
    shareholdersPledged: number
    shareholdersGoal: number
    percentOfTarget: string
    onInstrumentSelect: () => void
    sixPickExplanation: string
}) => {
    return (
        <div className="justify-self-center w-spill-over-mobile sm:w-spill-over rounded-lg rounded-tl-[50px] rounded-br-[50px] px-2 py-2 sm:px-4 sm:py-4 bg-violet-200">
            <div className="flex text-forest items-center pl-5 pb-2">
                <h4 className="text-2xl mt-1">SIX's pick</h4>
                <button className="top-10 ml-2" aria-label={sixPickExplanation}>
                    <InfoIconTooltip size={22}>{sixPickExplanation}</InfoIconTooltip>
                </button>
            </div>
            <Instrument
                key={instrument.securityCode}
                instrument={instrument}
                isSelected={isSelected}
                shareholdersPledged={shareholdersPledged}
                shareholdersGoal={shareholdersGoal}
                percentOfTarget={percentOfTarget}
                onInstrumentSelect={onInstrumentSelect}
            />
        </div>
    )
}

export const SecuritySelectButtons = ({ instruments, pledges, selectedInstrument, setSelectedInstrument }: Props) => {
    const { currentCampaign } = useCampaignStore()

    return (
        <div className="grid gap-4">
            {instruments.map((instrument) => {
                const isInstrumentSixPick = currentCampaign?.sixPickSecurityCode?.includes(instrument.securityCode)
                const instrumentCode = instrument.securityCode
                const isSelected = instrumentCode === selectedInstrument?.securityCode
                const shareholdersPledged = pledges ? pledges[`"${instrument.securityCode}"`] : 0
                const shareholdersGoal = currentCampaign?.pledgesGoal || 100 // FIXME: defaults to 100
                const percentOfTarget = Math.min((shareholdersPledged / shareholdersGoal) * 100, 100)
                    .toFixed(0)
                    .toString()

                return isInstrumentSixPick ? (
                    <InstrumentSixPick
                        key={instrument.securityCode}
                        sixPickExplanation={currentCampaign?.sixPickExplanation || ''}
                        instrument={instrument}
                        isSelected={isSelected}
                        shareholdersPledged={shareholdersPledged}
                        shareholdersGoal={shareholdersGoal}
                        percentOfTarget={percentOfTarget}
                        onInstrumentSelect={() => setSelectedInstrument(instrument)}
                    />
                ) : (
                    <Instrument
                        key={instrument.securityCode}
                        instrument={instrument}
                        isSelected={isSelected}
                        shareholdersPledged={shareholdersPledged}
                        shareholdersGoal={shareholdersGoal}
                        percentOfTarget={percentOfTarget}
                        onInstrumentSelect={() => setSelectedInstrument(instrument)}
                    />
                )
            })}
        </div>
    )
}
