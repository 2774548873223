import { CompanyAvatar } from '@/equities/companyAvatar'
import { PriceDiff } from './PriceDiff'
import { Area, AreaChart, ResponsiveContainer } from 'recharts'
import { TagIcon } from 'lucide-react'
import { cn } from '../utils/utils'

function MiniGraph({ data }: { data: any }) {
    return (
        <ResponsiveContainer width="100%" height={60}>
            <AreaChart data={data || []}>
                <defs>
                    <linearGradient id="colorClose" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#E4FBC6" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#E4FBC6" stopOpacity={0} />
                    </linearGradient>
                </defs>
                {data && data?.length > 0 && (
                    <Area
                        strokeWidth={2}
                        type="linear"
                        dataKey="close"
                        stroke="#32871D"
                        fillOpacity={1}
                        fill="url(#colorClose)"
                    />
                )}
            </AreaChart>
        </ResponsiveContainer>
    )
}

export function SecurityCard({
    securityCode,
    securityName,
    price,
    changeInPriceYTD,
    historicalPrices,
    ethicalTags,
    width,
    withEthicalTags = true,
    loading,
}: {
    securityCode: string
    securityName: string
    price: number
    changeInPriceYTD: number
    historicalPrices: any
    ethicalTags?: any
    withEthicalTags?: boolean
    width?: number
    loading?: boolean
}) {
    if (loading) return <LoadingCard />

    return (
        <div
            className={`self-stretch h-full justify-start items-start gap-6 inline-flex ${width ? `w-[${width}px]` : 'w-full'}`}
        >
            <div className="grow shrink basis-0 self-stretch rounded-[10px] shadow-lg flex-col justify-start items-start inline-flex">
                <div
                    className={cn(
                        'self-stretch h-[200px] px-6 pt-6 pb-4 bg-neutral-50 rounded-tl-[5px] rounded-tr-[5px] border-l border-r border-t border-[#d5d8d3] flex-col justify-between items-start flex',
                        {
                            'rounded-bl-[5px] pb-8 rounded-br-[5px] border-b border-[#d5d8d3]': !withEthicalTags,
                        },
                    )}
                >
                    <div className="self-stretch justify-start items-start gap-3.5 inline-flex">
                        <CompanyAvatar securityCode={securityCode} />
                        <div className=" h-[42px] flex-col justify-start items-start gap-2.5 inline-flex">
                            <div className="self-stretch h-[42px] flex-col justify-center items-start flex">
                                <div className="self-stretch text-black text-base font-semibold  leading-snug">
                                    {securityName}
                                </div>
                                <div className="self-stretch text-black text-sm  leading-tight">{securityCode}</div>
                            </div>
                        </div>
                    </div>
                    <div className="self-stretch h-10 flex-col justify-center items-center gap-[17px] flex">
                        <div className="self-stretch h-10 flex-col justify-center items-start flex">
                            <div className="self-stretch justify-center items-center gap-[17px] inline-flex">
                                <div className="grow shrink w-full basis-0 h-10 justify-between items-end gap-3 flex">
                                    <div className="flex-col justify-between items-start inline-flex">
                                        <div className="justify-start items-center gap-5 inline-flex w-full">
                                            <div className="w-[60px] text-black text-xs">Price</div>
                                            {!isNaN(changeInPriceYTD) && (
                                                <div className="w-[80px] min-w-fit text-black text-xs">
                                                    Change (YTD)
                                                </div>
                                            )}
                                        </div>
                                        <div className="justify-start items-center gap-5 inline-flex">
                                            <div className="w-[60px] text-black text-base font-bold">
                                                ${price?.toFixed(2)}
                                            </div>
                                            {!isNaN(changeInPriceYTD) && (
                                                <div className="grow shrink basis-0 h-[22px] justify-start items-center w-[60px] flex">
                                                    <PriceDiff
                                                        value={changeInPriceYTD}
                                                        label={`${Math.abs(changeInPriceYTD)}%`}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="grow shrink basis-0 self-stretch pb-px justify-center items-center flex">
                                    <div className="w-full h-[29px] relative">
                                        <MiniGraph data={historicalPrices} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {withEthicalTags && (
                    <div className="self-stretch px-6 pt-4 pb-6 bg-white rounded-bl-[5px] rounded-br-[5px] h-full border-l border-r border-b border-[#d5d8d3] flex-col justify-start items-start gap-4 flex">
                        <div className="justify-start items-center gap-2 inline-flex">
                            <TagIcon color="#404040" width={16} height={16} />
                            <div className="text-stone-700 text-base leading-normal">Ethical tags</div>
                        </div>
                        <div className="self-stretch justify-start items-center gap-2 inline-flex flex-wrap">
                            {ethicalTags?.map((tag: any) => (
                                <div
                                    className={`px-3 py-1.5 rounded-2xl backdrop-blur-[10px] justify-start items-center gap-1 w-min-fit flex ${tag.isTagPositive ? 'bg-lime-50' : 'bg-red-50'}`}
                                >
                                    <div className="text-black text-sm">{tag.tag}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

function LoadingCard() {
    return (
        <div className="h-[286px] rounded-[10px] flex-col justify-start items-start inline-flex">
            <div className="self-stretch h-[138px] px-6 pt-6 pb-4 bg-neutral-50 rounded-tl-[5px] rounded-tr-[5px] border-l border-r border-t border-[#d5d8d3] flex-col justify-start items-start gap-4 flex">
                <div className="self-stretch h-[42px] justify-start items-start gap-3.5 inline-flex">
                    <div className="w-[35px] h-[35px] bg-gray-200 rounded-[200px]" />
                    <div className="grow shrink basis-0 self-stretch flex-col justify-start items-start gap-1 inline-flex">
                        <div className="w-[254px] h-[21px] relative bg-gray-200 rounded" />
                        <div className="w-[104px] h-[17px] relative bg-gray-200 rounded" />
                    </div>
                </div>
                <div className="self-stretch h-10 justify-center items-end gap-6 inline-flex">
                    <div className="w-16 h-10 relative bg-gray-200 rounded" />
                    <div className="w-16 h-10 relative bg-gray-200 rounded" />
                    <div className="h-[30px] relative bg-gray-200 rounded" />
                </div>
            </div>
            <div className="self-stretch h-[148px] px-6 pt-4 pb-6 bg-white rounded-bl-[5px] rounded-br-[5px] border-l border-r border-b border-[#d5d8d3] flex-col justify-start items-start gap-4 flex">
                <div className="w-[104px] h-6 relative bg-gray-200 rounded" />
                <div className="self-stretch justify-start items-center gap-2 inline-flex">
                    <div className="w-[178px] h-[30px] relative bg-gray-200 rounded-3xl" />
                    <div className="w-[118px] h-[30px] relative bg-gray-200 rounded-3xl" />
                    <div className="w-[143px] h-[30px] relative bg-gray-200 rounded-3xl" />
                </div>
            </div>
        </div>
    )
}
