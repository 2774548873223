import { Helmet } from 'react-helmet'

import { FullScreenLoader } from '@/common/ui/FullScreenLoader'
import { APP } from '@/common/strings'
import { useFeatureFlags } from '@/common/featureFlags/featureFlags'
import { useFetchSecuritiesByTag, useFetchTrendingSecurities } from './tradingQueries'
import { SecurityCard } from '@/common/ui/SecurityCard'
import { H1 } from '@/common/ui'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { cn } from '@/common/utils/utils'

function TradingFilters({ filters, onFilter, selectedFilter }: any) {
    return (
        <div className="overflow-y-scroll pb-4 bg-white justify-start items-center gap-6 inline-flex">
            {filters.map((filter: any) => (
                <button
                    onClick={() => onFilter(filter)}
                    className={cn('py-2 min-w-fit flex-col justify-center items-center gap-2 inline-flex', {
                        'border-b-2 border-black': filter.name === selectedFilter.name,
                        'hover:border-b-2 hover:border-stone-200': filter.name !== selectedFilter.name,
                    })}
                >
                    <div className="text-black text-2xl font-normal font-['Inter'] leading-relaxed">{filter.emoji}</div>
                    <div className="text-black text-xs font-medium font-['Inter'] leading-none">{filter.name}</div>
                </button>
            ))}
        </div>
    )
}

const filters = [
    { name: 'Trending ETFs', emoji: '🔥' },
    { name: 'Renewable energy', emoji: '🔋' },
    { name: 'Energy transition minerals', emoji: '⛏️⚡️' },
    { name: 'Water efficiency', emoji: '💧' },
    { name: 'Nutritious foods', emoji: '🥕' },
    { name: 'Recycling and waste management', emoji: '♻️' },
    { name: 'Healthcare', emoji: '🏥' },
    { name: 'Electric vehicles', emoji: '🚗⚡️' },
    { name: 'Education', emoji: '🎓' },
    { name: 'Sustainable buildings', emoji: '🏡' },
    { name: 'Gender equality', emoji: '👩‍🤝‍👨' },
]

export function Trading() {
    const [filter, setFilter] = useState(filters[0])
    const { showTradingPage } = useFeatureFlags()
    const { data: trendingSecurities, isLoading: isLoadingTrending } = useFetchTrendingSecurities()
    const [filteredSecurities, setFilteredSecurities] = useState(trendingSecurities)
    const { data: securitiesByTag, refetch: refetchSecuritiesByTag, isLoading } = useFetchSecuritiesByTag(filter.name)

    useEffect(() => {
        if (filter.name !== 'Trending ETFs') {
            refetchSecuritiesByTag()
        }
    }, [filter, refetchSecuritiesByTag])

    useEffect(() => {
        if (filter.name === 'Trending ETFs') {
            setFilteredSecurities(trendingSecurities)
        } else {
            setFilteredSecurities(securitiesByTag)
        }
    }, [filter, trendingSecurities, securitiesByTag])

    // if (!trendingSecurities) return <FullScreenLoader />

    if (!showTradingPage) return null

    return (
        <div>
            <Helmet>
                <title>Trading | {APP.title}</title>
            </Helmet>
            {/* <NoTradingAccountAlert /> */}
            <H1>Trading</H1>
            <TradingFilters selectedFilter={filter} filters={filters} onFilter={(filter: any) => setFilter(filter)} />
            {isLoadingTrending || isLoading ? (
                <FullScreenLoader />
            ) : (
                <>
                    {filter.name === 'Trending ETFs' && (
                        <div className="h-[68px] flex-col justify-center items-start gap-2 inline-flex">
                            <div className="flex-col justify-start items-start gap-4 flex">
                                <div className="text-black text-sm font-semibold font-['Inter'] leading-tight">
                                    {filteredSecurities?.length} shares in Trending ETFs
                                </div>
                            </div>
                            <div className="self-stretch h-10 flex-col justify-start items-start gap-4 flex">
                                <div className="self-stretch text-gray-700 text-sm font-normal font-['Inter'] leading-tight">
                                    The companies listed below were selected by SIX based on being the largest
                                    sustainability Exchange-Traded Funds (ETFs) on the Australian Stock Exchange (ASX)
                                    and are not a recommendation to buy or sell these shares. Please ensure you conduct
                                    your own research before making any investment decisions.
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="grid mt-5 grid-wrap grid-cols-1 lg:grid-cols-2 gap-4 xl:grid-cols-3 gap-5">
                        {filteredSecurities?.map((security: any) => {
                            if (!security.securityDetails) return null
                            const price =
                                security.securityDetails.priceInfo.buyPrice ??
                                security.securityDetails.priceInfo.closingPrice
                            if (isNaN(security.changeInPriceYTD) || !price) return
                            return (
                                <Link className="w-full" to={`/equity/${security.securityDetails.securityCode}`}>
                                    <SecurityCard
                                        loading={isLoading}
                                        securityCode={security.securityDetails.securityCode}
                                        securityName={
                                            security.securityDetails.companyName ??
                                            security.securityName ??
                                            security.securityDetails.securityName
                                        }
                                        price={price}
                                        changeInPriceYTD={security.changeInPriceYTD}
                                        historicalPrices={security.historicalPrices}
                                        ethicalTags={security.ethicalTags}
                                        withEthicalTags
                                    />
                                </Link>
                            )
                        })}
                    </div>
                </>
            )}
        </div>
    )
}
