import { FC, useState } from 'react'
import { cn } from '@/common/utils/utils'

type Props = {
    securityCode: string
    className?: string
    imgClassName?: string
    height?: number
    width?: number
}
export const CompanyAvatar: FC<Props> = ({ securityCode, className, imgClassName, height = 8, width = 8 }) => {
    const [imageFound, setImageFound] = useState(true)

    return (
        <div className={cn('flex space-x-3 text-inherit w-max', className)} title={securityCode}>
            <div
                className={cn(
                    'bg-gray-100 rounded-full bg-white p-1 border-1 border-gray-300 shrink-0 overflow-hidden',
                    `w-${width} h-${height}`,
                    `sm:w-${width} sm:h-${height}`,
                )}
            >
                {imageFound && (
                    <img
                        src={`/images/companies/${securityCode.toLowerCase()}.webp`}
                        alt={securityCode}
                        className={cn(imgClassName)}
                        onError={() => setImageFound(false)}
                    />
                )}
                {!imageFound && (
                    <div
                        className={`text-primary text-[${height}px] font-bold text-center h-full flex items-center justify-center`}
                    >
                        {securityCode.toUpperCase()}
                    </div>
                )}
            </div>
        </div>
    )
}
