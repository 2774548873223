import { InstrumentT, InstrumentWithCampaignMetadataT } from '@/common/types'
import { useEffect, useState } from 'react'
import { TradeModal } from './tradeModal'
import useTradeModalStore from './tradeModalStore'
import { getCompanyBySecurityCode } from './tradingApi'
import { toast } from 'sonner'
import useCampaignStore from './campaignStore'
import { getPledgeBySecurityCode } from '@/campaigns/campaignsApi'
import { analytics } from '@/common/analytics/analytics'
import { useSubscribeCampaign } from '@/campaigns/campaignsQueries'

export const TradeModalProvider = ({ children }: { children: React.ReactNode }) => {
    const [selectedTradeInstruments, setSelectedTradeInstruments] = useState<InstrumentT[] | null>(null)
    const [isFetchingInstruments, setIsFetchingInstruments] = useState<boolean>(false)
    const [isFetchingPledges, setIsFetchingPledges] = useState<boolean>(false)
    const [pledges, setPledges] = useState<{ [key: string]: number } | null>(null)

    const { securityCodes, setSecurityCodes, orderAction, setOrderAction } = useTradeModalStore()
    const { currentCampaign, setCurrentCampaign } = useCampaignStore()
    const subscribeCampaign = useSubscribeCampaign()

    const handleOrder = async (securityCodes: string[]) => {
        setIsFetchingInstruments(true)
        try {
            const instruments = await Promise.all(securityCodes.map((code) => getCompanyBySecurityCode(code)))
            const instrumentsWithDeadline: InstrumentWithCampaignMetadataT[] = instruments.map(
                (instrument: InstrumentT) => {
                    const companyMetadata = currentCampaign?.targetCompaniesMetadata?.find(
                        (metadata) => metadata.code === instrument.securityCode,
                    )
                    if (companyMetadata) {
                        return { ...instrument, campaignDeadline: companyMetadata.campaignDeadline }
                    }
                    return instrument
                },
            )

            const sortedInstruments = instrumentsWithDeadline.sort((a, b) => {
                if (a.securityCode === currentCampaign?.sixPickSecurityCode) return -1
                if (b.securityCode === currentCampaign?.sixPickSecurityCode) return 1
                if (!a.campaignDeadline && b.campaignDeadline) return 1
                if (a.campaignDeadline && !b.campaignDeadline) return -1
                if (!a.campaignDeadline || !b.campaignDeadline) return 0
                return new Date(a.campaignDeadline).getTime() - new Date(b.campaignDeadline).getTime()
            })

            setSelectedTradeInstruments(sortedInstruments)
            orderAction === 'BUY' && fetchPledges(securityCodes)
        } catch (error) {
            toast.error('Sorry, there was a problem starting your order')
        }
        setIsFetchingInstruments(false)
        currentCampaign && subscribeCampaign.mutate(currentCampaign.campaignId)
    }

    const fetchPledges = async (securityCodes: string[]) => {
        setIsFetchingPledges(true)
        if (!currentCampaign) {
            setIsFetchingPledges(false)
            return
        }
        const pledges: { [key: string]: number } = {}
        await Promise.all(
            securityCodes.map(async (code) => {
                pledges[`"${code}"`] = await getPledgeBySecurityCode(currentCampaign.campaignId, code)
            }),
        )
        setPledges(pledges)
        setIsFetchingPledges(false)
    }

    useEffect(() => {
        if (securityCodes) {
            handleOrder(securityCodes)
            orderAction === 'SELL'
                ? analytics.track('SellOrder Started', { security_code: securityCodes[0] })
                : analytics.track('BuyOrder Started', { security_codes: securityCodes })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [securityCodes])

    const onTradeModalClose = () => {
        setSelectedTradeInstruments(null)
        setSecurityCodes(null)
        setCurrentCampaign(null)
        setOrderAction(null)
    }

    return (
        <>
            {children}
            <TradeModal
                onClose={onTradeModalClose}
                loading={isFetchingInstruments || isFetchingPledges}
                instruments={selectedTradeInstruments}
                pledges={pledges}
                orderAction={orderAction}
            />
        </>
    )
}
