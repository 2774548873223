import { useFlags } from 'launchdarkly-react-client-sdk'

export function useFeatureFlags() {
    const {
        portfolioPage,
        companySearch,
        tradingPage,
        esgProfiles,
        companySearchBar,
        companyProfilePages,
        downtimePage,
    } = useFlags()

    return {
        showPortfolioPage: portfolioPage,
        showCompanySearch: companySearch,
        showTradingPage: tradingPage,
        showEsgProfiles: esgProfiles,
        showCompanySearchBar: companySearchBar,
        showCompanyProfilePages: companyProfilePages,
        showDownTimePage: downtimePage || false,
    }
}
