import { analytics } from '@/common/analytics/analytics'
import { Link } from 'react-router-dom'

export function GettingStartedPreview() {
    return (
        <>
            <div className="h-[22px] justify-start mb-3 mt-4 items-center inline-flex">
                <div className="text-center text-black text-xl font-normal">Get started investing ethically</div>
            </div>
            <div className="p-6 bg-white shadow-lg rounded-[10px] shadow border border-[#d5d8d3] justify-start items-center gap-6 flex-wrap inline-flex">
                <img
                    width={570}
                    height={446}
                    className="bg-cover rounded"
                    src="https://s3-alpha-sig.figma.com/img/5dc8/fb2d/bc63148ac64f9c62e4bb07e292b93a25?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ggse1WTYK3Ld89PxBfh3uvgJe9x4a0OGQruSBNk4dgR0BfXLD396KwvHm~5P0mwFFORgXmOvRnpJdkOoOXZ~83upQk8SlQvD2jfMyZCFnaBhXW~SEz4LiNHx8ErptbOL6wEwcGvBoyWyKIVsgvqlqmIJEMK3Vu49zgKgO2G1e9qy9UMGLZK3CsP85ZB1KWpRwhdrWWATMIi0TbNl0pkyoJmMhP6jZPkQCDO4LFpHkZHtIPvIm8wYNfCxWZiU7KQ1uFOlARyP7QaUr2vvZXbx7iojbXpuNWCCdLsQv4LYEaK3C2p1crlL~-YmiLDI9JTPYJ5ZYeDV8KSNVD4n0U8TSA__"
                />
                <div className="h-[150px] w-full xl:w-[460px] flex-col justify-start items-start gap-6 inline-flex">
                    <div className="text-left text-black text-base font-medium font-['Inter'] leading-snug">
                        What shares should I buy first?
                    </div>
                    <div className="self-stretch text-black text-sm font-normal font-['Inter'] leading-tight">
                        Founder of SIX Adam Verwey shares his tips on how you can start investing ethically with any
                        amount.{' '}
                    </div>
                    <Link
                        to="/getting-started"
                        target="_blank"
                        className="h-10 px-6 py-2 bg-[#ecefe9] rounded-[100px] justify-center items-center gap-2.5 inline-flex"
                        onClick={() => analytics.track('GettingStartedPage Viewed')}
                    >
                        <div className="text-black text-sm font-medium font-['Inter'] leading-tight">Read more</div>
                    </Link>
                </div>
            </div>
        </>
    )
}
