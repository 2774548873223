import type { Milestone } from '@/campaigns/CampaignTimeline'
import {
    BriefcaseIcon,
    HandshakeIcon,
    MailIcon,
    MegaphoneIcon,
    NewspaperIcon,
    PartyPopperIcon,
    UsersIcon,
} from 'lucide-react'
import moment from 'moment'

export function getMilestoneTextAndIcon(milestone: Milestone, color: string = 'white') {
    let milestoneText
    let icon
    switch (milestone.title) {
        case 'campaign-launched':
            milestoneText = 'Launch campaign'
            icon = <MegaphoneIcon color={color} />
            break
        case '100-shareholders':
            milestoneText = 'Obtain 100 shareholders'
            icon = <UsersIcon color={color} />
            break
        case 'send-company-demands':
            milestoneText = 'Send company our demands'
            icon = <MailIcon color={color} />
            break
        case 'lodge-resolution':
            milestoneText = 'Lodge resolution'
            icon = <NewspaperIcon color={color} />
            break
        case 'agm-showdown':
            milestoneText = 'AGM showdown'
            icon = <BriefcaseIcon color={color} />
            break
        case 'results-announcement':
            milestoneText = 'Announce results'
            icon = <HandshakeIcon color={color} />
            break
        case 'campaign-complete':
            milestoneText = 'Campaign complete'
            icon = <PartyPopperIcon color={color} />
            break

        default:
            milestoneText = milestone.title
    }

    return { milestoneText, icon }
}

export function getOrderedAndCurrentMilestones(milestones: Milestone[]) {
    let milestoneOrder = [
        'campaign-launched',
        '100-shareholders',
        'send-company-demands',
        'lodge-resolution',
        'agm-showdown',
        'results-announcement',
    ]

    const hunderedShareholdersDate = milestones.find((milestone) => milestone.title === '100-shareholders')?.date
    const sendCompanyDemandsDate = milestones.find((milestone) => milestone.title === 'send-company-demands')?.date

    if (moment(hunderedShareholdersDate).isAfter(moment(sendCompanyDemandsDate))) {
        milestoneOrder = [
            'campaign-launched',
            'send-company-demands',
            '100-shareholders',
            'lodge-resolution',
            'agm-showdown',
            'results-announcement',
        ]
    }

    const orderedMilestones = milestones.sort((a, b) => {
        return milestoneOrder.indexOf(a.title) - milestoneOrder.indexOf(b.title)
    })

    const currentMilestone = orderedMilestones.find((milestone) => moment(milestone.date).isAfter(moment()))

    return { orderedMilestones, currentMilestone }
}
