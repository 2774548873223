import { H1 } from '@/common/ui'
import useUserStore from '@/user/userStore'
import { ToDoList } from './ToDoList'
import { PortfolioSummary } from './PortfolioSummary'
import { RecommendedETFs } from './RecommendedETFs'
import { PortfolioValue } from './PortfolioValue'
import { GettingStartedPreview } from './GettingStartedPreview'
import { UserCampaignsSummary } from './UserCampaignsSummary'
import moment from 'moment'

export function Dashboard() {
    const { user } = useUserStore()

    const welcomeText = parseInt(moment().format('HH')) < 12 ? 'Good morning' : 'Good afternoon'

    return (
        <main>
            <H1 className="text-[32px]">
                {welcomeText} {user?.firstName} 🌱
            </H1>
            <div className="grid mb-10 grid-cols-1 min-[1300px]:grid-cols-3 gap-2">
                <PortfolioSummary />
                <PortfolioValue />
                <ToDoList />
            </div>
            <UserCampaignsSummary />
            <RecommendedETFs />
            <GettingStartedPreview />
        </main>
    )
}
