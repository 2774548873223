import { OrderExpiry, OrderAction, OrderType } from '@/common/types'
import { create } from 'zustand'

interface State {
    orderType?: OrderType
    orderAction: OrderAction
    limitPrice?: number
    orderExpiry: OrderExpiry
    numberOfShares?: number
    instrumentPrice?: number
    approximateInvestmentAmount?: number
    requestId: string | null
}
interface TradeState extends State {
    setOrderAction: (action: OrderAction) => void
    setOrderType: (age: OrderType) => void
    setLimitPrice: (price?: number) => void
    setOrderExpiry: (expiry: OrderExpiry) => void
    setNumberOfShares: (numberOfShares?: number) => void
    setInstrumentPrice: (price: number) => void
    setApproximateInvestmentAmount: (amount?: number) => void
    setRequestId: () => void
    resetState: () => void
}

const initialState: State = {
    orderAction: 'BUY',
    orderExpiry: 'VALID_FOR_DAYS_7',
    numberOfShares: undefined,
    approximateInvestmentAmount: undefined,
    limitPrice: undefined,
    requestId: null,
}

const useTradeStore = create<TradeState>((set) => ({
    ...initialState,
    setOrderAction: (action: OrderAction) => set(() => ({ orderAction: action })),
    setOrderType: (type: OrderType) => set(() => ({ orderType: type })),
    setLimitPrice: (price?: number) => set(() => ({ limitPrice: price })),
    setOrderExpiry: (expiry: OrderExpiry) => set(() => ({ orderExpiry: expiry })),
    setNumberOfShares: (numberOfShares?: number) => set(() => ({ numberOfShares })),
    setInstrumentPrice: (price: number) => set(() => ({ instrumentPrice: price })),
    setRequestId: () => set(() => ({ requestId: crypto.randomUUID() })),
    setApproximateInvestmentAmount: (amount?: number) => set(() => ({ approximateInvestmentAmount: amount })),
    resetState: () =>
        set(() => ({
            ...initialState,
            requestId: crypto.randomUUID(),
        })),
}))

export default useTradeStore
