import { H3 } from '@/common/ui'
import { CircleAlertIcon } from 'lucide-react'
import { FC } from 'react'

type Props = {
    className?: string
}

export const NoTradingAccountAlert: FC<Props> = ({ className }) => (
    <div className={`flex flex-col gap-4 mb-10 ${className}`}>
        <div className="flex items-center gap-4 mb-4">
            <CircleAlertIcon size={40} />
            <H3 className="mb-0 md:text-2xl">Your trading account is still being set up</H3>
        </div>
        <p>This usually takes around 20 minutes in business hours, or by 10:30am on the next business day.</p>
    </div>
)
