import { ClockIcon } from 'lucide-react'
import moment from 'moment'

export const Deadline = ({ deadline, iconSize }: { deadline?: string; iconSize: number }) => {
    if (!deadline) return

    const today = moment()
    const deadlineMoment = moment(deadline)
    const hoursLeft = deadlineMoment.diff(today, 'hours')
    const daysLeft = deadlineMoment.diff(today, 'days')

    if (daysLeft > 1) {
        return (
            <div className="flex items-center">
                <ClockIcon size={iconSize} />
                <span className="text-xs ml-1 text-zinc-600">
                    <b>{daysLeft}</b> days left
                </span>
            </div>
        )
    } else if (hoursLeft < 24 && hoursLeft > 1) {
        const hoursLeft = deadlineMoment.diff(today, 'hours')
        return (
            <div className="flex items-center text-red-700">
                <ClockIcon size={16} />
                <span className="text-xs ml-1">
                    <b>{hoursLeft} hours</b> left
                </span>
            </div>
        )
    } else if (hoursLeft < 1) {
        const minutesLeft = deadlineMoment.diff(today, 'minutes')
        if (minutesLeft > 0) {
            return (
                <div className="flex items-center text-red-700">
                    <ClockIcon size={16} />
                    <span className="text-xs ml-1">
                        <b>{minutesLeft} minutes</b> left
                    </span>
                </div>
            )
        }
    }
}
