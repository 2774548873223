import { FC } from 'react'

import { ORDER_EXPIRY, ORDER_TYPE } from '@/common/constants'
import { InstrumentT } from '@/common/types'
import Money from '@/common/models/money'
import useTradeStore from '@/trading/tradeStore'
import { tradeEstimatedBrokerage } from '@/common/utils/utils'

type TradeReviewItemProps = {
    label: string
    value: string | number
}
const Item: FC<TradeReviewItemProps> = ({ label, value }) => {
    return (
        <li className="flex justify-between py-3 border-b">
            <span>{label}</span>
            <strong className="text-right ml-2">{value}</strong>
        </li>
    )
}

type Props = {
    instrument: InstrumentT
}
export const ReviewItems: FC<Props> = ({ instrument }) => {
    const orderType = useTradeStore((state) => state.orderType)
    const limitPrice = useTradeStore((state) => state.limitPrice)
    const orderExpiry = useTradeStore((state) => state.orderExpiry)
    const numberOfShares = useTradeStore((state) => state.numberOfShares)
    const instrumentPrice = useTradeStore((state) => state.instrumentPrice)
    const action = useTradeStore((state) => state.orderAction)
    if (!instrumentPrice || !numberOfShares || !orderType) return null
    const sharesAmount = Money.of(
        orderType === 'LIMIT' ? limitPrice : action === 'SELL' ? instrumentPrice : instrumentPrice * 1.02,
    ).multiply(numberOfShares)
    const fee = tradeEstimatedBrokerage(sharesAmount)
    const totalAmount = action === 'SELL' ? sharesAmount.subtract(fee) : sharesAmount.add(fee)
    const decimalPrecisionToDisplay = limitPrice?.toString().split('.')[1]?.length || 2
    return (
        <ul>
            <Item label="Company" value={`${instrument.companyName} (${instrument.securityCode})`} />
            <Item label="Order Direction" value={action === 'BUY' ? 'Buy' : 'Sell'} />
            <Item label="Order Type" value={ORDER_TYPE[orderType]} />
            <Item label="Expiry Duration" value={ORDER_EXPIRY[orderExpiry]} />
            <Item label="Shares" value={`${numberOfShares} shares`} />
            {orderType === 'LIMIT' && limitPrice && (
                <Item label="Price per unit" value={`${Money.of(limitPrice).toString(decimalPrecisionToDisplay)}`} />
            )}
            <Item label="Estimated Amount" value={`${sharesAmount.toString(decimalPrecisionToDisplay)}`} />
            <Item label="Estimated Brokerage" value={`${fee.toString(decimalPrecisionToDisplay)}`} />
            <Item label="Estimated Total" value={`${totalAmount.toString(decimalPrecisionToDisplay)}`} />
        </ul>
    )
}
