import { Deadline } from '@/common/ui/Deadline'
import { getMilestoneTextAndIcon, getOrderedAndCurrentMilestones } from '@/common/utils/milestones'
import { CompanyAvatar } from '@/equities/companyAvatar'
import { ChevronRightIcon, LinkIcon } from 'lucide-react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { toast } from 'sonner'
import { CampaignsWithHoldings, useCampaignsWithHoldings } from './useCampaignsWithHoldings'
import type { Milestone } from '@/campaigns/CampaignTimeline'
import { TargetCompanyMetadata } from '@/common/types'
import { FullScreenLoader } from '@/common/ui/FullScreenLoader'
import { cn } from '@/common/utils/utils'
import { analytics } from '@/common/analytics/analytics'

export function UserCampaignsSummary() {
    const { campaignsWithHoldings, isLoading } = useCampaignsWithHoldings()

    return <UserCampaignsSummaryView isLoading={isLoading} campaigns={campaignsWithHoldings} />
}

const checkIfCampaignIsFinished = (milestones: Milestone[]) => {
    const resultsAnnouncementMilestone = milestones.find((milestone) => milestone.title === 'results-announcement')
    return moment().isAfter(moment(resultsAnnouncementMilestone?.date))
}

function CompanyMilestone({ currentMilestone, milestones }: { currentMilestone?: Milestone; milestones: Milestone[] }) {
    const { icon, milestoneText } = getMilestoneTextAndIcon(
        {
            title: currentMilestone?.title ?? '100-shareholders',
            date: currentMilestone?.date ?? new Date().toDateString(),
        },
        '#F9F9F9',
    )

    const isCampaignFinished = checkIfCampaignIsFinished(milestones)
    // if (!milestone) return
    return (
        <>
            <div className="self-stretch flex-col w-1/2 min-[1200px]:w-[250px] justify-start items-start gap-2 inline-flex">
                <div className="flex-col justify-start items-start flex">
                    <div className="text-stone-700 text-sm  leading-tight">Next step</div>
                </div>
                <div className="w-56 justify-start items-center gap-4 inline-flex">
                    <div className="grow shrink basis-0 self-stretch justify-start items-center gap-4 flex">
                        <div
                            className={cn(
                                'w-10 h-10 p-2.5 bg-[#223A32] rounded-[100px] justify-center items-center gap-2.5 flex',
                            )}
                        >
                            <div className="flex items-center">{icon}</div>
                        </div>
                        <div className="flex-col justify-start content-between h-full items-start inline-flex">
                            <div className="justify-start items-start gap-2.5 inline-flex">
                                <div className="text-black mb-1 text-sm  leading-tight">{milestoneText}</div>
                            </div>
                            <div className="text-stone-700 text-[10px] leading-tight">
                                {!currentMilestone || isCampaignFinished
                                    ? ''
                                    : moment(currentMilestone?.date).format('DD MMM YYYY')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

// TODO: fix clear form button when buying share

function CompanyOverview({ company, campaign }: { company: TargetCompanyMetadata; campaign: any }) {
    const { currentMilestone, orderedMilestones } = getOrderedAndCurrentMilestones(company.milestones)

    const pledges = campaign.pledges[company.code]
    const isCampaignFinished = checkIfCampaignIsFinished(orderedMilestones)

    return (
        <div>
            <Link
                to={`campaigns/${campaign.campaignId}`}
                onClick={() => analytics.track('PledgedCampaign Viewed', { campaignId: campaign.campaignId })}
            >
                <div className="mb-3 justify-between items-center gap-6 inline-flex">
                    <CompanyAvatar className="mr-1" securityCode={company.code} height={12} width={12} />
                    <div className="self-stretch flex-col justify-center items-start gap-2 inline-flex">
                        <div className="self-stretch items-center text-black text-base font-semibold leading-snug gap-2 inline-flex">
                            {company.name}
                            {isCampaignFinished && (
                                <div className="h-[30px] px-3 py-1.5 bg-[#e3fbc5] rounded-2xl backdrop-blur-[10px] justify-start items-center inline-flex">
                                    <div className="text-black text-xs font-light">Complete</div>
                                </div>
                            )}
                        </div>
                        <div className="self-stretch text-stone-700 text-sm">ASX:{company.code}</div>
                    </div>
                </div>
            </Link>
            <div className="self-stretch rounded-lg bg-[#f8f8f8] min-[1024px]:bg-white p-4 min-[1024px]:p-0 justify-between items-center">
                <div className="justify-between items-center gap-6 flex sm:flex-row flex-wrap flex-col">
                    <div className="w-full min-[1024px]:w-[250px] min-[1400px]:w-[300px] self-stretch flex-col justify-between items-start inline-flex">
                        <div className="text-stone-700 text-sm">Shareholders</div>
                        <div className="self-stretch justify-start items-center gap-2 inline-flex">
                            <style>{`.${company.code} { --bar-width: ${Math.min(pledges, 100)}%; }`}</style>
                            <div
                                className={`grow shrink basis-0 h-2 bg-[#f0f0f0] rounded-lg flex-col justify-start items-start gap-2.5 inline-flex ${company.code}`}
                            >
                                <div
                                    className={`${pledges === 100 ? 'bg-lime-100' : 'bg-[#c5f73c]'} w-[--bar-width] h-2 relative bg-[#c5f73c] rounded-lg`}
                                ></div>
                            </div>
                        </div>
                        <div className="justify-start items-center gap-2 inline-flex">
                            <div>
                                <span className="text-black text-sm font-semibold  leading-tight">{pledges}</span>
                                <span className="text-black text-sm leading-tight">/100</span>
                            </div>
                            {company.campaignDeadline && moment().isBefore(company.campaignDeadline) && (
                                <>
                                    <div className="w-1.5 h-1.5 bg-black rounded-full" />
                                    <div className="justify-start items-center gap-1 flex">
                                        <div className="justify-start items-center gap-1.5 flex">
                                            <div className="w-4 h-4 relative">
                                                <Deadline iconSize={16} deadline={company.campaignDeadline} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <CompanyMilestone milestones={orderedMilestones} currentMilestone={currentMilestone} />
                    <div className="inline-flex w-full min-[1200px]:w-[250px] min-[1024px]:justify-between">
                        <div className="h-9 px-6 py-5 bg-[#ecefe9] w-full min-[1024px]:w-[180px] rounded-[100px] justify-center items-center gap-2 inline-flex">
                            <div className="w-4 h-4 relative">
                                <LinkIcon width={16} height={16} />
                            </div>
                            <button
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        `${window.location.href}campaigns/${campaign.campaignId}`,
                                    )
                                    toast('Link copied!')
                                    analytics.track('CampaignLink Copied', { campaignId: campaign.campaignId })
                                }}
                                className="text-black text-center text-sm"
                            >
                                Share campaign
                            </button>
                        </div>
                        <div className="self-end items-center gap-6 flex">
                            <div className="w-10 h-10 rounded-[100px] justify-center items-center gap-2.5 flex">
                                <Link
                                    to={`campaigns/${campaign.campaignId}`}
                                    onClick={() =>
                                        analytics.track('PledgedCampaignPage Viewed', {
                                            campaignId: campaign.campaignId,
                                        })
                                    }
                                    className="w-6 h-6 relative"
                                >
                                    <ChevronRightIcon color="black" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function UserCampaignsSummaryView({
    campaigns,
    isLoading,
}: {
    campaigns: CampaignsWithHoldings[]
    isLoading: boolean
}) {
    if (isLoading) return <FullScreenLoader />

    const hasNoCampaigns = campaigns?.length === 0 && !isLoading

    if (!isLoading && campaigns?.length === 0)
        return (
            <div className="w-full h-[173px] py-8 bg-white rounded-[10px] shadow border border-[#d5d8d3] flex-col justify-center items-center gap-8 inline-flex">
                <div className="text-black text-sm  leading-[16.80px]">
                    You aren’t participating in any campaigns yet...
                </div>
                <Link
                    to="/campaigns"
                    target="_blank"
                    className="h-[60px] text-black px-[30px] py-2.5 bg-[#ecefe9] rounded-[100px] justify-center items-center gap-2.5 inline-flex"
                >
                    View all campaigns
                </Link>
            </div>
        )

    return (
        <div className="flex-col justify-start items-start gap-4 w-full inline-flex">
            <div className="self-stretch justify-between items-center inline-flex">
                <div className="text-center text-black text-xl font-['Serious Sans'] leading-snug">Your campaigns</div>
                <div className="px-[17px] py-1.5 rounded-full justify-start items-center gap-6 flex">
                    <Link
                        to="/campaigns"
                        target="_blank"
                        className="text-center text-[#137157] text-sm  underline leading-[14px]"
                        onClick={() => analytics.track('AllCampaignsPage Viewed')}
                    >
                        View all campaigns {'>'}
                    </Link>
                </div>
            </div>
            {hasNoCampaigns ? (
                <div className="w-full h-[173px] py-8 bg-white rounded-[10px] shadow border border-[#d5d8d3] flex-col justify-center items-center gap-8 inline-flex">
                    <div className="text-black text-sm  leading-[16.80px]">
                        You aren’t participating in any campaigns yet...
                    </div>
                    <Link
                        to="/campaigns"
                        target="_blank"
                        className="h-[60px] text-black px-[30px] py-2.5 bg-[#ecefe9] rounded-[100px] justify-center items-center gap-2.5 inline-flex"
                    >
                        View all campaigns
                    </Link>
                </div>
            ) : (
                campaigns.map((campaign) => {
                    const isMultipleCompanies = (campaign.pledgedCompanies?.length ?? 0) > 1

                    return (
                        <div className="rounded-sm shadow-lg border border-[#d5d8d3] self-stretch rounded-lg flex-col justify-start items-start flex">
                            <div className="self-stretch rounded-lg p-6 h-[97px] bg-[#f8f8f8] flex-col justify-start items-start gap-2 flex">
                                <div className="justify-start items-center gap-4 inline-flex">
                                    <div className="flex-col justify-start items-start gap-2 inline-flex">
                                        <div className="justify-start items-center gap-2.5 inline-flex">
                                            <div className="grow shrink basis-0 text-black text-xl font-['Serious Sans'] leading-normal">
                                                {campaign.title}
                                            </div>
                                        </div>
                                        <div className="justify-start items-center gap-2.5 inline-flex">
                                            <div className="grow shrink basis-0 text-stone-700 text-sm font-['Serious Sans'] leading-[16.80px]">
                                                {campaign.pledgedCompanies?.length === 1
                                                    ? '1 company'
                                                    : `${campaign.pledgedCompanies?.length} companies`}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex-col p-6 min-w-full justify-start items-start gap-4 flex">
                                {campaign?.pledgedCompanies?.map((company) => (
                                    <div className={isMultipleCompanies ? 'w-full pb-4 border-b' : 'w-full'}>
                                        <CompanyOverview campaign={campaign} company={company} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )
                })
            )}
        </div>
    )
}
