import { FC } from 'react'
import { cn } from '@/common/utils/utils'
import { CompanyAvatar } from './companyAvatar'

type Props = {
    instrument: {
        companyName: string
        securityCode: string
    }
    className?: string
    nameClassName?: string
}
export const CompanyHead: FC<Props> = ({ instrument, nameClassName, className }) => {
    const securityCode = instrument.securityCode
    return (
        <div className={cn('flex py-2 space-x-3 text-inherit w-max', className)} title={instrument.companyName}>
            <CompanyAvatar securityCode={securityCode} />
            <div className="flex flex-col">
                <span className="font-semibold">{securityCode}</span>
                <span className={cn('text-xs line-clamp-1', nameClassName)}>{instrument.companyName}</span>
            </div>
        </div>
    )
}
