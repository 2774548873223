import { DataTable } from '@/common/ui/data-table'
import { HoldingT } from '@/common/types'
import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { CompanyHead } from '@/equities/companyHead'

type holdingsProps = {
    holdings: HoldingT[]
}

export function PledgedHoldings(props: holdingsProps) {
    const { holdings } = props

    const columns: ColumnDef<HoldingT>[] = useMemo(
        () => [
            {
                accessorKey: 'securityCode',
                enableSorting: false,
                header: () => {
                    return <span className="text-primary font-bold">Your Pledged Shares</span>
                },
                cell: ({ row }) => {
                    const instrumentCode = row.original.securityCode
                    return (
                        <CompanyHead
                            nameClassName="max-w-20 md:max-w-40 xl:max-w-52 2xl:max-w-max"
                            instrument={{
                                securityCode: instrumentCode,
                                companyName: row.original.name,
                            }}
                        />
                    )
                },
            },
        ],
        [],
    )
    return (
        <DataTable
            columns={columns}
            data={holdings.length ? holdings : []}
            classNameHeader="px-0"
            classNameCell="px-0"
            noDataMessage="No shares held yet..."
        />
    )
}
