import { TradingAccountDetails, TradingAccountSubmissionData } from '@/common/types'
import { H2 } from '@/common/ui/h2'
import { InfoIconTooltip } from '@/common/ui/tooltip'

type AccountDetailsProps = {
    account: TradingAccountDetails
}

export const AccountDetails = ({ account }: AccountDetailsProps) => {
    const AccountDetailsItem = ({ title, info, tooltip }: { title: string; info: string; tooltip?: string }) => (
        <li className="border-b py-4 xs:grid xs:grid-cols-[220px_1fr_100px] xs:min-h-16 xs:place-content-center">
            <div className="font-bold flex items-center">
                {title}
                {tooltip && (
                    <div className="ml-1">
                        <InfoIconTooltip>
                            <p>{tooltip}</p>
                        </InfoIconTooltip>
                    </div>
                )}
            </div>
            <div className="hidden xs:flex items-center">{info}</div>
        </li>
    )
    if (!account) return
    const submissionData = JSON.parse(account.submissionData) as TradingAccountSubmissionData
    const accountName = `${submissionData.firstName} ${submissionData.middleName && `${submissionData.middleName} `}${submissionData.lastName}`
    return (
        <>
            <H2 className="mt-5">Account details</H2>
            <ul className="mt-2 mb-16">
                <AccountDetailsItem title="Account Name" info={accountName} />
                <AccountDetailsItem title="Account Type" info="Individual" />
                <AccountDetailsItem title="Account Number" info={account.accountNumber} />
                <AccountDetailsItem
                    title="HIN"
                    info={account.hin}
                    tooltip="Your HIN (Holder Identification Number) is a unique identifier for your trading account. Keep it secure and reference it when needed."
                />
            </ul>
        </>
    )
}
