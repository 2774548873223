import { useParams } from 'react-router'
import { useEsgProfile } from './researchQueries'
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer'
import { Block, BLOCKS, Document, Inline, Text } from '@contentful/rich-text-types'
import { ReactNode } from 'react'
import { H1, H2 } from '@/common/ui'
import { UnorderedList } from '@/common/ui/UnorderedList'
import { ListItem } from '@/common/ui/ListItem'
import { OnThisPage } from './OnThisPage'
import { cn } from '@/common/utils/utils'
import { Snapshot } from './Snapshot'
import { FullScreenLoader } from '@/common/ui/FullScreenLoader'

export function EsgProfile({ showBranding = true }: { showBranding?: boolean }) {
    const securityCode = useParams().securityCode?.toUpperCase()
    const { data: profile, isLoading } = useEsgProfile(securityCode)

    const options: Options = {
        renderNode: {
            // @ts-ignore
            [BLOCKS.HEADING_2]: (node: Block | Inline, children: ReactNode) => (
                <div id={`${(node.content[0] as Text).value}`}>
                    <H2 className="mt-12 first:mt-0">{children}</H2>
                </div>
            ),
            // @ts-ignore
            [BLOCKS.PARAGRAPH]: (node: Block | Inline, children: ReactNode) => <p className="mb-4">{children}</p>,
            // @ts-ignore
            [BLOCKS.UL_LIST]: (node: Block | Inline, children: ReactNode) => <UnorderedList>{children}</UnorderedList>,
            // @ts-ignore
            [BLOCKS.LIST_ITEM]: (node: Block | Inline, children: ReactNode) => <ListItem>{children}</ListItem>,
            // @ts-ignore
            [BLOCKS.EMBEDDED_ENTRY]: (node: Block | Inline) => {
                const entry = profile?.data.content.links.entries.block.find(
                    (entry: any) => entry.sys.id === node.data.target.sys.id,
                )
                const snapshot = entry.itemsCollection.items
                return <Snapshot snapshot={snapshot} />
            },
        },
    }

    if (isLoading) return <FullScreenLoader />

    return (
        <>
            {showBranding && (
                <div className={cn('flex py-2 gap-4 items-center mb-12')}>
                    <div className="bg-gray-100 rounded-full p-1 w-16 h-16 border-1 border-gray-300 shrink-0 overflow-hidden">
                        <img
                            src={`/images/companies/${profile?.data.company.asxCode.toLowerCase()}.webp`}
                            alt={securityCode}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null
                                // currentTarget.src = placeholderImg
                            }}
                        />
                    </div>
                    <div className="flex flex-row items-end gap-4">
                        <H1 className="m-0 p-0 pt-2 text-5xl xs:text-6xl md:text-6xl">
                            {profile?.data.company.companyName}
                        </H1>
                        <span className={cn('text-sm line-clamp-1 pb-[12px]')}>
                            {profile?.data.company.securityNameOfficial} ({securityCode})
                        </span>
                    </div>
                </div>
            )}
            <div className="max-w-[1200px] grid grid-cols-1 lg:grid-cols-[2fr_1fr] gap-16">
                <div className="row-start-2 lg:row-auto">
                    {documentToReactComponents(profile?.data.content.json as Document, options)}
                </div>
                <OnThisPage document={profile?.data.content.json as Document} />
            </div>
        </>
    )
}
