import * as yup from 'yup'
import { Helmet } from 'react-helmet'
import { Onboarding } from '@/onboarding/OnboardingPageTemplate'
import { APP } from '@/common/strings'
import { LabelInput } from '@/common/ui/LabelInput'
import { signOut } from 'aws-amplify/auth'
import { useNavigate } from 'react-router'
import { APP_ROUTES } from '@/common/constants'
import useUserStore from '@/user/userStore'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useUpdateName } from '@/user/userQueries'
import { analytics } from '@/common/analytics/analytics'

type NameForm = {
    salutation: string
    firstName: string
    lastName: string
}

const schema = yup
    .object({
        salutation: yup.string().required('Please enter a title').min(2, 'Please enter a valid title'),
        firstName: yup.string().required('Please enter your first name').min(2, 'Please enter a valid first name'),
        lastName: yup.string().required('Please enter your last name').min(2, 'Please enter a valid last name'),
    })
    .required()

export function Name() {
    const navigate = useNavigate()
    const updateName = useUpdateName()
    const { user, setUser } = useUserStore()

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur',
    })

    const onSubmit = (data: NameForm) => {
        updateName.mutate(data)
        if (!user) return
        setUser({ ...user, ...data })
        analytics.identify(user?.userId, { firstName: data.firstName, lastName: data.lastName })
    }

    useEffect(() => {
        if (!updateName.isSuccess) return
        analytics.track('Name Updated')
        navigate(APP_ROUTES.ADDRESS)
    }, [updateName.isSuccess, navigate])

    useEffect(() => {
        // attach existing data on mount
        setValue('salutation', user?.salutation || '')
        setValue('firstName', user?.firstName || '')
        setValue('lastName', user?.lastName || '')
    }, [user, setValue])

    return (
        <>
            <Helmet>
                <title>Your Name | {APP.title}</title>
            </Helmet>
            <Onboarding
                title="Your Name"
                isLoading={updateName.isPending}
                cta="Next"
                onBack={() => signOut()}
                onSubmit={handleSubmit(onSubmit)}
            >
                <LabelInput
                    {...register('salutation')}
                    className="max-w-[200px]"
                    type="text"
                    id="salutation"
                    required
                    formNoValidate
                    label="Title"
                    placeholder="Eg Mr, Mrs, Ms"
                    error={errors.salutation?.message}
                />
                <LabelInput
                    {...register('firstName')}
                    type="text"
                    id="firstName"
                    required
                    formNoValidate
                    label="First Name"
                    placeholder="First Name"
                    error={errors.firstName?.message}
                />
                <LabelInput
                    {...register('lastName')}
                    type="text"
                    id="lastName"
                    required
                    formNoValidate
                    label="Last Name"
                    placeholder="Last Name"
                    error={errors.lastName?.message}
                />
            </Onboarding>
        </>
    )
}
