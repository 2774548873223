import { analytics } from '@/common/analytics/analytics'
import { SecurityCard } from '@/common/ui/SecurityCard'
import { useFetchRecommendedSecurities } from '@/trading/tradingQueries'
import { Link } from 'react-router-dom'

function RecommendedETFsView({ recommendedETFs }: { recommendedETFs: any }) {
    if (!recommendedETFs) {
        return null
    }
    return (
        <div className="flex-col mt-10 justify-start overflow-x-scroll w-full pb-6 items-start gap-4 inline-flex">
            <div className="self-stretch justify-start items-start gap-8 inline-flex">
                <div className="grow shrink basis-0 h-[22px] justify-start items-center gap-8 flex">
                    <div className="text-center text-black text-xl font-['Serious Sans'] leading-snug">
                        Trending ethical ETFs
                    </div>
                </div>
            </div>
            <div className="text-[#6B7280] mb-3 text-sm">
                The companies listed above were selected by SIX based on being the largest sustainability
                Exchange-Traded Funds (ETFs) on the Australian Stock Exchange (ASX) and are not a recommendation to buy
                or sell these shares. Please ensure you conduct your own research before making any investment
                decisions.
            </div>
            <div className="inline-flex gap-6">
                {recommendedETFs?.map((etf: any) => (
                    <Link
                        to={`/equity/${etf.securityDetails.securityCode}`}
                        onClick={() =>
                            analytics.track('EthicalETFPage Viewed', { securityCode: etf.securityDetails.securityCode })
                        }
                    >
                        <SecurityCard
                            width={400}
                            securityCode={etf.securityDetails.securityCode}
                            securityName={etf.securityName ?? etf.securityDetails.securityName}
                            price={etf.securityDetails.priceInfo.buyPrice ?? etf.securityDetails.priceInfo.closingPrice}
                            changeInPriceYTD={etf.changeInPriceYTD}
                            historicalPrices={etf.historicalPrices}
                            ethicalTags={etf.ethicalTags}
                        />
                    </Link>
                ))}
            </div>
        </div>
    )
}

export function RecommendedETFs() {
    const { data: recommendedETFs } = useFetchRecommendedSecurities()

    return <RecommendedETFsView recommendedETFs={recommendedETFs} />
}
