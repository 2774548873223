import { FC, ReactNode } from 'react'

import { EquityT, Maybe } from '@/common/types'
import { H3 } from '@/common/ui/h3'

type KeyStatisticItemProps = {
    label: string
    value: ReactNode
}
const CompanyProfileItem: FC<KeyStatisticItemProps> = ({ label, value }) => {
    return (
        <li className="flex space-x-1 items-end last:border-none justify-between px-2 py-3 border-b border-neutral-300">
            <span className="text-sm text-left">{label}</span>
            <strong className="text-sm font-semibold text-right">{value}</strong>
        </li>
    )
}

type Props = {
    about: Maybe<EquityT['about']>
}
export const CompanyProfile: FC<Props> = ({ about }) => {
    if (!about) return

    return (
        <aside className="flex flex-col gap-8">
            <div>
                <H3 className="">About</H3>
                <div className="w-full bg-[#F9F9F9] rounded">
                    <ul className="w-full px-4 py-4 rounded-xl bg-gray-100">
                        {about.ceo && <CompanyProfileItem label="CEO" value={about.ceo} />}
                        {about.sector && <CompanyProfileItem label="Sector" value={about.sector} />}
                        {about.industry && <CompanyProfileItem label="Industry" value={about.industry} />}
                        {about.foundedYear && <CompanyProfileItem label="Founded" value={about.foundedYear} />}
                        {about.employees && <CompanyProfileItem label="Employees" value={about.employees} />}
                        {about.webAddress && (
                            <CompanyProfileItem
                                label="Website"
                                value={
                                    <a href={`https://${about.webAddress}`} target="_blank" className="break-all">
                                        {about.webAddress}
                                    </a>
                                }
                            />
                        )}
                    </ul>
                </div>
            </div>
        </aside>
    )
}
