import { useEffect, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import { Info } from 'lucide-react'

import Money from '@/common/models/money'
import { useFetchBalance, useFetchPayId } from '@/wallet/walletQueries'
import { Button, H2 } from '@/common/ui'
import { Dialog, DialogContent } from '@/common/ui/dialog'
import { ValuesGrid } from '@/common/ui/ValuesGrid'
import { ValuesGridItem } from '@/common/ui/ValuesGridItem'
import WithdrawDialogContainer from './WithdrawDialogContainer'
import useKycStore from '@/kyc/kycStore'
import { seconds } from '@/common/utils/utils'
import { analytics } from '@/common/analytics/analytics'
import DepositOptions from './DepositOptions'

export default function FundsAndBalances() {
    const [isFundDetailsOpen, setIsFundDetailsOpen] = useState(false)
    const [openWithdrawDialog, setOpenWithdrawDialog] = useState(false)
    const [isPayIdCopied, setIsPayIdCopied] = useState(false)
    const [reservedBalance, setReservedBalance] = useState(0)
    const { kycComplete } = useKycStore()
    const { data: payIdDetails } = useFetchPayId(kycComplete)
    const { data: balance } = useFetchBalance({
        enabled: kycComplete,
        refetchInterval: seconds(10),
    })

    const openFundDetails = () => {
        setIsFundDetailsOpen(true)
        analytics.track('AddFundsButton Clicked')
    }

    const handleHavePaid = () => {
        setIsFundDetailsOpen(false)
        analytics.track('HavePaidButton Clicked')
    }

    const handleTransferLater = () => {
        setIsFundDetailsOpen(false)
        analytics.track('WillTransferLaterButton Clicked')
    }

    const closeFundDetails = () => {
        setIsFundDetailsOpen(false)
    }

    const handleOpenWithdrawalModal = () => {
        setOpenWithdrawDialog(true)
        analytics.track('WithdrawFundsButton Clicked')
    }

    const fundsModalRef = useRef(null)
    useClickAway(fundsModalRef, () => {
        closeFundDetails()
    })

    useEffect(() => {
        if (!isPayIdCopied) return
        setTimeout(() => {
            setIsPayIdCopied(false)
        }, 2000)
    }, [isPayIdCopied])

    useEffect(() => {
        if (balance) {
            setReservedBalance(balance.totalBalance - balance.availableBalance)
        }
    }, [balance])

    return (
        <>
            <section>
                <div className="flex space-x-5">
                    <H2 className="mb-6">Funds &amp; Balances</H2>
                    <Button disabled={!payIdDetails} variant="secondary" onClick={openFundDetails}>
                        Add Funds
                    </Button>
                    <Button
                        disabled={!(balance && balance.availableBalance > 0)}
                        variant="outline"
                        onClick={handleOpenWithdrawalModal}
                    >
                        Withdraw Funds
                    </Button>
                </div>
                <ValuesGrid>
                    <ValuesGridItem label="Available Balance" value={`${Money.of(balance?.availableBalance ?? 0)}`} />
                    <ValuesGridItem label="Total Balance" value={`${Money.of(balance?.totalBalance ?? 0)}`} />
                    <ValuesGridItem
                        label="Settlements Expected"
                        value={`${Money.of(balance?.settlemenetBalance ?? 0)}`}
                    />
                </ValuesGrid>
                {reservedBalance > 0 && (
                    <p className="mt-6">
                        <span className="font-bold">{`${Money.of(reservedBalance)}`}</span> is currently reserved for
                        your open orders.
                    </p>
                )}
            </section>

            {isFundDetailsOpen && payIdDetails && (
                <Dialog open={isFundDetailsOpen}>
                    <DialogContent
                        ref={fundsModalRef}
                        onClose={closeFundDetails}
                        className="px-10 py-14 max-h-[90vh] max-w-[600px] overflow-y-auto"
                    >
                        <H2 className="mb-2">Add funds</H2>
                        <p>
                            To add funds to your wallet, go to your online banking and transfer to our account using
                            {payIdDetails.payId && ' PayID or'} Bank account details provided below.
                        </p>
                        {/* node */}
                        <p className="p-4 flex flex-col sm:flex-row items-start sm:items-center gap-3 my-3 bg-muted rounded-tl-3xl rounded-br-3xl">
                            <Info size={36} strokeWidth={1} fill="#fff" className="flex-shrink-0" />
                            <div>
                                <strong>How much should I transfer?</strong>
                                <p className="text-balance">
                                    The minimum amount you will need <span className="font-bold">per buy order</span> is
                                    up to approximately <span className="font-bold">$580</span> depending on the share
                                    price, to cover the minimum order size plus a 2% buffer, and fees.
                                </p>
                            </div>
                        </p>
                        <DepositOptions payIdDetails={payIdDetails} />
                        <div className="flex mt-6 flex-col sm:flex-row gap-5">
                            <Button onClick={handleHavePaid} className="w-full" variant="secondary">
                                I have paid
                            </Button>
                            <Button onClick={handleTransferLater} className="w-full" variant="outline">
                                I'll transfer later
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            )}

            {openWithdrawDialog && (
                <WithdrawDialogContainer
                    showWithdrawDialog={openWithdrawDialog}
                    onClose={() => setOpenWithdrawDialog(false)}
                    availableBalance={balance?.availableBalance || 0}
                />
            )}
        </>
    )
}
