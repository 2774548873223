import { InstrumentT } from '@/common/types'
import { H2 } from '@/common/ui/h2'
import useTradeStore from '@/trading/tradeStore'
import { Button } from '@/common/ui/button'
import { Undo2 } from 'lucide-react'
import { FC, useEffect, useState } from 'react'
import { usePlaceBuyOrder, usePlaceSellOrder } from '@/trading/tradingQueries'
import { Checkbox } from '@/common/ui/checkbox'
import useCampaignStore from './campaignStore'
import { usePledgeForCampaign } from '@/campaigns/campaignsQueries'
import { analytics } from '@/common/analytics/analytics'
import { ReviewItems } from './revewItems'

type Props = {
    instrument: InstrumentT
    pledges?: { [key: string]: number } | null
    onBackToOrder: () => void
    onSubmit: () => void
}
export const TradeReview: FC<Props> = ({ instrument, onBackToOrder, onSubmit }) => {
    const [isPledged, setIsPledged] = useState<boolean>(true)
    const placeBuyOrder = usePlaceBuyOrder()
    const { currentCampaign } = useCampaignStore()
    const pledgeCampaign = usePledgeForCampaign()
    const placeSellOrder = usePlaceSellOrder()
    const action = useTradeStore((state) => state.orderAction)
    const requestId = useTradeStore((state) => state.requestId)
    const orderType = useTradeStore((state) => state.orderType)
    const limitPrice = useTradeStore((state) => state.limitPrice)
    const orderExpiry = useTradeStore((state) => state.orderExpiry)
    const numberOfShares = useTradeStore((state) => state.numberOfShares)
    const price = useTradeStore((state) => state.instrumentPrice)

    useEffect(() => {
        if (placeBuyOrder.isSuccess || placeSellOrder.isSuccess) {
            onSubmit()
        }
    }, [placeBuyOrder.isSuccess, placeSellOrder.isSuccess, onSubmit])

    if (!price || !numberOfShares || !action) return null
    const sharesAmount = orderType === 'LIMIT' ? limitPrice : numberOfShares * price

    const placeOrder = () => {
        if (!requestId || !numberOfShares || !orderType) return
        if (isPledged) {
            pledgeForCampaign()
        }
        if (action === 'BUY') {
            placeBuyOrder.mutate({
                requestId,
                orderType,
                requestedPrice: orderType === 'LIMIT' ? limitPrice : undefined,
                units: numberOfShares,
                validity: orderExpiry,
                securityCode: instrument.securityCode,
            })
            analytics.track('BuyOrder Submitted', {
                request_id: requestId,
                security_code: instrument.securityCode,
                requested_price: sharesAmount,
                number_of_shares: numberOfShares,
                validity: orderExpiry,
            })
        }
        if (action === 'SELL') {
            placeSellOrder.mutate({
                requestId,
                orderType,
                requestedPrice: orderType === 'LIMIT' ? limitPrice : undefined,
                units: numberOfShares,
                validity: orderExpiry,
                securityCode: instrument.securityCode,
            })
            analytics.track('SellOrder Submitted', {
                request_id: requestId,
                security_code: instrument.securityCode,
                requested_price: sharesAmount,
                number_of_shares: numberOfShares,
                validity: orderExpiry,
            })
        }
    }

    const pledgeForCampaign = () => {
        if (!currentCampaign) return
        pledgeCampaign.mutate({ campaignId: currentCampaign.campaignId, securityCode: instrument.securityCode })
    }

    const PledgeCheckbox = () => (
        <div className="items-top flex space-x-2 bg-[#E4FBC6] p-5">
            <Checkbox
                id="pledge"
                checked={isPledged}
                onCheckedChange={() => {
                    setIsPledged(!isPledged)
                }}
            />
            <div className="grid gap-1.5">
                <label htmlFor="pledge" className="text-sm font-medium mt-[-3px]">
                    By ticking this box, I give authority to Sustainable Investment Exchange Pty Ltd and it’s agents to
                    execute an Agency Agreement on my behalf at a time after purchase of these shares has been settled,
                    and agree to the Terms and Conditions of{' '}
                    <a
                        target="_blank"
                        href="https://cdn.prod.website-files.com/659642fe2d32d0e5e8e622ae/66a048770d71655d8bcae3d9_SIX%20Agency%20Agreement%20Terms%20and%20Conditions.pdf"
                    >
                        SIX’s Agency Agreement
                    </a>
                    .
                </label>
            </div>
        </div>
    )

    return (
        <div>
            <H2 className="text-center">Review {action === 'BUY' ? 'Buy' : 'Sell'} Order</H2>
            <ReviewItems instrument={instrument} />
            <p className="my-5 text-sm">
                Trade confirmation will record brokerage charges and SIX does not accept any responsibility for market
                movements.{' '}
                {action === 'BUY' && (
                    <span>
                        You must ensure sufficient funds are available to cover the total consideration of the order.
                    </span>
                )}
            </p>
            {currentCampaign && action === 'BUY' ? <PledgeCheckbox /> : null}
            <div className="flex justify-between items-center mt-8">
                <Button variant="link" onClick={onBackToOrder} className="m-0 p-0">
                    <Undo2 size={16} /> Amend
                </Button>
                <Button
                    loading={placeBuyOrder.isPending || placeSellOrder.isPending}
                    variant="secondary"
                    size="lg"
                    onClick={placeOrder}
                >
                    Submit Order
                </Button>
            </div>
        </div>
    )
}
