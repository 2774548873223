export const Errors = {
    server: 'We could not process your request. Please try again, or contact our staff at help@six-invest.com.au (we will get back to you as soon as possible).',
    unknown:
        'Something went wrong! :( Please try again, or contact our staff at help@six-invest.com.au (we will get back to you as soon as possible).',
}

export const APP = {
    title: 'SIX - Sustainable Investment Exchange',
}

export const TRADING = {
    marketOrderNote:
        'Order will fill at whatever the best price currently available in the market is, if your order is not filled immediately at that price and the market price moves away, your order will be turned into a limit order at the price you paid.',
    brokerageNote: 'Brokerage fee is 0.1% of the trade value, with a minimum of $9.90',
    limitOrderNote: 'Order will fill when the stock price hits your specified target price.',
    liveTradingNote:
        'You are in a live trading environment. All investments carry risks. Please ensure you have understood and accept these risks before investing. It is important to know that you are vulnerable to market volatility and losses. Any information provided by SIX has not considered the individual circumstances of any individual.',
}
