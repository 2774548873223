import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer'
import { Block, BLOCKS, Document, Inline, Text } from '@contentful/rich-text-types'
import { ReactNode } from 'react'
import { H3 } from '@/common/ui'

export function OnThisPage({ document }: { document: Document }) {
    if (!document) return null

    const filteredDocument = {
        ...document,
        content: document.content.filter((node) => node.nodeType === 'heading-2'),
    }

    const options: Options = {
        renderNode: {
            // @ts-ignore
            [BLOCKS.HEADING_2]: (node: Block | Inline, children: ReactNode) => {
                return (
                    <a className="text-foreground" href={`#${(node.content[0] as Text).value}`}>
                        <p className="px-8 py-4 font-light hover:bg-[#ECEFE9]">{children}</p>
                    </a>
                )
            },
        },
    }

    return (
        <aside>
            <H3>On this page</H3>
            <div className="bg-[#F9F9F9] rounded-lg py-4">{documentToReactComponents(filteredDocument, options)}</div>
        </aside>
    )
}
